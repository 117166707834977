import React from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import TimePicker from 'rc-time-picker';
import BasicTooltip from '../../../../../components/BasicToolTip';
import MenuHeading from '../../../../../components/MenuHeading';
import AddTagDialog from '../../../../../components/AddTagDialog';
import DayPicker from '../../../../accessRestrictions/components/DayPicker';
import * as tagActions from '../../../../../reducers/tagsReducer';

import lang from '../lang';

import '../styles.css';

// Form Step 3
const LeagueOptionalsForm = () => {
  const dispatch = useDispatch();
  const league = useSelector((state) => state.leagues.league);
  const company = useSelector((state) => state.companies.companyInfo);
  const { isPowerleague } = company;
  const companyTags = useSelector((state) => state.tags.companyTags);
  const selectedTags = useSelector((state) => state.tags.selectedTags);
  const filteredTags = companyTags ? companyTags.filter((tag) => tag.category === 'LEAGUE_TYPE') : [];

  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
  } = useFormikContext();

  const gameLengthOptions = [
    { value: 20, label: '20 Minutes' },
    { value: 30, label: '30 Minutes' },
    { value: 35, label: '35 Minutes' },
    { value: 40, label: '40 Minutes' },
    { value: 45, label: '45 Minutes' },
    { value: 50, label: '50 Minutes' },
    { value: 55, label: '55 Minutes' },
    { value: 60, label: '1 hour' },
  ];

  return (
    <>
      <div>League Optionals</div>
      <div className="input-league-form-wrapper">
        <FormControlLabel
          control={(
            <Switch
              id="isCustomPointsOptions"
              checked={values.isCustomPointsOptions}
              value={values.isCustomPointsOptions}
              onChange={handleChange}
            />
  )}
          label="Set Custom Points and Forfeit Options ?"
        />
      </div>

      <ConditionallyVisible condition={values.isCustomPointsOptions}>
        <div className="input-league-form-wrapper">
          <TextField
            id="pointsForWin"
            variant="outlined"
            error={touched?.pointsForWin && Boolean(errors.pointsForWin)}
            helperText={touched?.pointsForWin && errors.pointsForWin}
            defaultValue={values.pointsForWin}
            value={values.pointsForWin}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Points For Win"
            type="number"
            required
          />
        </div>
        <div className="input-league-form-wrapper">
          <TextField
            id="pointsForDraw"
            variant="outlined"
            error={touched?.pointsForDraw && Boolean(errors.pointsForDraw)}
            helperText={touched?.pointsForDraw && errors.pointsForDraw}
            defaultValue={values.pointsForDraw}
            value={values.pointsForDraw}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Points for draw"
            type="number"
            required
          />
        </div>
        <div className="input-league-form-wrapper">
          <TextField
            id="pointsForLoss"
            variant="outlined"
            error={touched?.pointsForLoss && Boolean(errors.pointsForLoss)}
            helperText={touched?.pointsForLoss && errors.pointsForLoss}
            defaultValue={values.pointsForLoss}
            value={values.pointsForLoss}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Points for loss"
            type="number"
            required
          />
        </div>
        <div className="input-league-form-wrapper">
          <TextField
            id="goalsForForfeit"
            variant="outlined"
            error={touched?.goalsForForfeit && Boolean(errors.goalsForForfeit)}
            helperText={touched?.goalsForForfeit && errors.goalsForForfeit}
            value={values.goalsForForfeit}
            onChange={handleChange}
            label="Goals For Winner in Forfeit"
            type="number"
          />
          <BasicTooltip title={lang.goalsForWinnerInForfeitTooltip} />
        </div>
      </ConditionallyVisible>
      {/* <div className="input-league-form-wrapper">
        <FormControlLabel
          control={(
            <Switch
              id="isDivision"
              checked={values.isDivision}
              value={values.isDivision}
              onChange={handleChange}
            />
  )}
          label="League Has Multiple Divisions ?"
        />
      </div> */}
      <ConditionallyVisible condition={values.isDivision}>
        <TextField
          id="numberOfDivisions"
          variant="outlined"
          type="number"
          error={touched?.numberOfDivisions && Boolean(errors.numberOfDivisions)}
          helperText={touched?.numberOfDivisions && errors.numberOfDivisions}
          value={values.numberOfDivisions}
          onChange={handleChange}
          label="Number of divisions"
        />
      </ConditionallyVisible>
      <div className="input-league-form-wrapper">
        <FormControlLabel
          control={(
            <Switch
              id="leagueTimings"
              checked={values.leagueTimings}
              value={values.leagueTimings}
              onChange={handleChange}
            />
  )}
          label="Specify League Timings ?"
        />
      </div>
      <ConditionallyVisible condition={values.leagueTimings}>
        <MenuHeading title="Kick Off Time" />
        <TimePicker
          value={values.kickOffTime ? moment(values.kickOffTime) : values.kickOffTime}
          showSecond={false}
          minuteStep={5}
          clearIcon={null}
          allowEmpty
          eslint-disable-next-line
          max-len
          onChange={(value) => setFieldValue('kickOffTime', moment(value).format('YYYY-MM-DD HH:mm'))}
          placeholder="Select Kick Off Time"
        />
        <MenuHeading title="Day of Week" />
        <DayPicker
          value={values.dayOfWeek}
          onUpdate={(e) => setFieldValue('dayOfWeek', e.weekday)}
          choosenWeekday={values.dayOfWeek}
        />
        <MenuHeading title="Game Length" />
        <Select
          name="gameLength"
          options={gameLengthOptions}
          onChange={(option) => setFieldValue(
            'gameLength', option.value,
          )}
        />
      </ConditionallyVisible>
      <div className="input-league-form-wrapper">
        <FormControlLabel
          control={(
            <Switch
              id="visible"
              checked={values.visible}
              value={values.visible}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
                 )}
          label="Publicly visible ?"
        />
      </div>
      <ConditionallyVisible condition={isPowerleague}>
        <div className="input-league-form-wrapper" style={{ flexDirection: 'column', alignItems: 'normal' }}>
          <p>{`${selectedTags.length} tags selected`}</p>
          <br />
          <AddTagDialog
            tags={filteredTags}
            selectedTags={league.tags}
            currentSelectedTags={selectedTags}
            addTag={(value) => dispatch(tagActions.addToSelectedTags(value))}
            removeTag={(value) => dispatch(tagActions.removeFromSelectedTags(value))}
            setSelectedTags={(tags) => dispatch(tagActions.updateSelectedTags(tags))}
            tagRelation="League"
          />
        </div>
      </ConditionallyVisible>
    </>

  );
};

export default LeagueOptionalsForm;
