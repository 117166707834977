import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Schedule, AccountBalance } from '@material-ui/icons';
import { generateTotalWithTaxText } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { Button, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { requestPayout } from '../../reducers/payoutsReducer';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import Back from '../../components/Back';
import PayoutOrdersTable from './PayoutTable';
import PayoutReconciliationButton from './PayoutReconciliationButton';
import { StatusTag, payoutStatusColors } from '../../components/StatusTag';

const PayoutView = () => {
  const dispatch = useDispatch();
  const { location } = window;
  const { pathname } = location;
  const payoutId = pathname.split('/').pop();
  const payout = useSelector((state) => state.payouts.payout);
  const companyInfo = useSelector((state) => state.companies.companyInfo);
  const isLoading = useSelector((state) => state.payouts.isLoading);

  useEffect(() => {
    dispatch(requestPayout(payoutId));
  }, []);

  if (!payout || !companyInfo) return null;

  const { stripeId, currencySym, timezone } = companyInfo;
  const {
    arrivalDate, amount, status, stripeId: stripePayoutId,
  } = payout;

  return (
    <>
      <div style={{ padding: '1rem' }}>
        <Back />
        <div className="subscription-view-card">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
          >
            <Typography style={{ fontSize: '1.5rem', fontWeight: 600, cursor: 'pointer' }}>Payout Details</Typography>
            <Typography style={{
              fontSize: '1rem', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer',
            }}
            >
              <Schedule color="secondary" />
              {`Arrival Date: ${moment(arrivalDate).tz(timezone).format('ll')}`}
            </Typography>
            <Typography style={{
              fontSize: '1.2rem', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer',
            }}
            >
              <AccountBalance color="secondary" />
              {`Total: ${generateTotalWithTaxText({ total: amount }, currencySym, true)}`}
            </Typography>

            <div style={{ display: 'flex', width: '25%', gap: '0.5rem' }}>
              <StatusTag
                label={status && status.toUpperCase()}
                colorScheme={payoutStatusColors[status]?.colorScheme}
                hideTooltip={status !== 'pending'}
                toolTipText={status === 'pending'
                  ? 'Payout is pending. Your paid breakdown will be available soon, please contact your account manager if you have any queries.'
                  : null}
              />

              {payout && <PayoutReconciliationButton payout={payout} hideStatus />}
            </div>

            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://dashboard.stripe.com/${stripeId}/payouts/${stripePayoutId}`}
              style={{ fontSize: '1rem', fontWeight: 600, cursor: 'pointer' }}
              variant="contained"
              color="primary"
            >
              View in Stripe
            </Button>
          </div>
        </div>
      </div>

      {isLoading
        ? <LoadingSkeletonDataTable />
        : (
          <div style={{ padding: '0rem 1rem' }}>
            {payout.stripeResponse.method === 'instant' ? (
              <Alert severity="info">
                <AlertTitle>Instant Payout</AlertTitle>
                This payout was processed instantly in your Stripe dashboard,
                therefore there are no orders are associated with this payout.
              </Alert>
            ) : (
              <PayoutOrdersTable
                payout={payout}
                stripeCompanyId={stripeId}
                currencySym={currencySym}
                timezone={timezone}
              />
            )}

          </div>
        )}
    </>
  );
};

export default PayoutView;
