import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import moment from 'moment';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@material-ui/core';
import JSON2CSV from 'json2csv';
import fileDownload from 'js-file-download';
import { fetchOrders } from '../../reducers/addonsReducer';
import DateRangePickerAndQuickDates from '../../components/Date/DateRangePickerAndQuickDatesWrapper';
import DownloadPdf from '../../components/DownloadPdf';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import { BreakdownCard } from '../payments/Components/BreakdownCard';
import { useCompany } from '../../hooks';
import { MultiSiteSelector } from '../payments/Components/MultiSiteSelector';

const OrderHistory = () => {
  const dispatch = useDispatch();
  const { currency, timezone, sites } = useCompany();

  const [selectedView, setSelectedView] = useState('ORDER_HISTORY');
  const [selectedDates, setSelectedDates] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [selectedSiteIds, setSelectedSiteIds] = useState([]);

  const orders = useSelector(
    (state) => state.addons.posOrders,
  )?.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));

  const getInventorySales = () => {
    dispatch(fetchOrders(selectedView, selectedDates, selectedSiteIds));
  };

  const getOrderHistory = () => {
    dispatch(fetchOrders(selectedView, selectedDates, selectedSiteIds));
  };

  const formatItemsString = (allocations, currency) => {
    if (!allocations || allocations?.length === 0) return [];

    // Group allocations by addon.id
    const groupedItems = allocations.reduce((acc, a) => {
      const key = a?.addon?.id;
      if (!acc[key]) {
        acc[key] = { ...a, quantity: 0 };
      }
      acc[key].quantity += 1;
      return acc;
    }, {});

    // Convert grouped object into sorted array
    const sortedItems = Object.values(groupedItems)
      .sort((a, b) => a.addon?.category?.name.localeCompare(b.addon?.category?.name)
      || a.addon?.name.localeCompare(b.addon?.name));

    // Create formatted string
    return sortedItems
      .map((a) => `${a.addon?.name} ${getCurrencySymbol(currency)}${a.price.toFixed(2)}${a.quantity > 1 ? ` (${a.quantity}x)` : ''} (${a.addon?.category?.name})`);
  };

  const handleExport = (columns, data, exportType) => {
    if (!exportType) throw new Error('Export type is required');

    // Format the exported data to match what is displayed in the table
    const formattedData = data.map((row) => ({
      'Order ID': row.id,
      'Created at': moment(row.createdAt).format('ddd, DD MMM YYYY HH:mm'),
      'Order Total': row.total,
      'Payment Status': row.paymentStatus,
      Status: row.status,
      Site: row.site?.name || '',
      Items: formatItemsString(row.allocations, currency).join(' | '),
    }));

    const fileName = 'Pitchbooking_POS_Orders';

    if (exportType === 'pdf') {
      try {
        const rowData = formattedData.map((row) => Object.values(row));
        DownloadPdf(
          Object.keys(formattedData[0]),
          rowData,
          `${fileName}.pdf`,
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const csvData = JSON2CSV({
          data: formattedData,
          fields: Object.keys(formattedData[0]), // Ensure headers match keys
          excelStrings: false,
        });
        fileDownload(csvData, `${fileName}.csv`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const totalAmount = orders?.reduce((sum, order) => sum + (order.total || 0), 0) || 0;
  const currencySym = getCurrencySymbol(currency);

  useEffect(() => {
    if (sites) setSelectedSiteIds(sites.map((s) => s.id));
  }, [sites]);

  return (
    <div>
      <Paper style={{ padding: 15 }}>
        <h1>Select Your Report</h1>
        <Select value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
          <option value="ORDER_HISTORY">Order History</option>
          <option value="INVENTORY_SALES">Inventory Sales</option>
        </Select>
        {selectedView === 'ORDER_HISTORY' && (
        <>
          <h1>Order History</h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              padding: '1rem',
              backgroundColor: '#F5F5F5',
              border: '1px solid #ddd',
              borderRadius: '0.75rem',
              marginBottom: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
              }}
            >
              <DateRangePickerAndQuickDates
                startDate={selectedDates !== null ? moment(selectedDates?.startDate)
                  : selectedDates.startDate}
                endDate={selectedDates !== null ? moment(selectedDates?.endDate)
                  : selectedDates.endDate}
                onOrderHistoryDatesChange={(dates) => setSelectedDates(dates)}
                includePaymentsByTime
              />

              <div style={{
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: '8px',
                gap: '8px',
                flex: '0 1 300px',
                maxWidth: '300px',
              }}
              >
                <MultiSiteSelector
                  sitesList={sites ?? []}
                  selectedSiteIds={selectedSiteIds}
                  handleSiteChange={setSelectedSiteIds}
                />
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '0.75rem',
            }}
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={() => getOrderHistory()}
              >
                Update
              </Button>
            </div>
          </div>
          <MaterialTable
            data={orders}
            title="Pitchbooking POS Orders"
            icons={{
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              Export: () => <SaveAlt />,
              ResetSearch: () => <Clear />,
              SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
            }}
            columns={[
              {
                title: 'Order ID',
                field: 'id',
                render: (row) => (
                  <a href={`/point-of-sale/${row.id}`}>{row.id}</a>
                ),
              },
              {
                title: 'Created at',
                field: 'createdAt',
                customSort: (a, b) => a.createdAt.localeCompare(b.createdAt),
                render: (row) => (
                  <p>
                    {moment(row.createdAt).tz(timezone).format('ddd, DD MMM YYYY HH:mm')}
                  </p>
                ),
              },
              {
                title: 'Order Total',
                field: 'total',
                render: (row) => (
                  <p>
                    {getCurrencySymbol(currency)}
                    {row.total?.toFixed(2)}
                  </p>
                ),
              },
              {
                title: 'Payment Status',
                field: 'paymentStatus',
              },
              {
                title: 'Status',
                field: 'status',
              },
              {
                title: 'Site',
                field: 'site.name',
              },
              {
                title: 'Items',
                field: 'allocations',
                render: (row) => (
                  <ul>
                    {formatItemsString(row.allocations, currency)?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                ),
              },
            ]}
            options={{
              search: true,
              selection: false,
              emptyRowsWhenPaging: false,
              tableLayout: 'auto',
              exportButton: true,
              exportFileName: 'Pitchbooking POS Orders',
              exportCsv: (columns, data) => handleExport(columns, data, 'csv'),
              exportPdf: (columns, data) => handleExport(columns, data, 'pdf'),
              pageSize: 50,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: false,
              showTitle: false,
              headerStyle: {
                backgroundColor: '#efefef',
                fontWeight: 'bold',
              },
            }}
            localization={{
              toolbar: {
                exportTitle: 'Download',
              },
            }}
          />
          <PitchbookingUserOnly>
            <br />
            <BreakdownCard
              title="TOTAL"
              count={orders.length}
              type="TOTAL"
              total={totalAmount.toFixed(2)}
              currencySym={currencySym}
              totalOrderAmount={totalAmount}
              componentType="methods"
            />
          </PitchbookingUserOnly>
        </>
        )}
        {selectedView === 'INVENTORY_SALES' && (
        <>

          <Paper
            style={{
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              overflowX: 'hidden',
              width: '100%',
              zIndex: 1,
            }}
            component={Paper}
          >
            <h1>Inventory Sales</h1>
            <div style={{
              display: 'flex',
              flexWrap: 'none',
              marginTop: '20px',
              marginLeft: '20px',
              marginBottom: '40px',
              padding: '20px',
              alignItems: 'center',
              zIndex: 1,
            }}
            >
              <DateRangePickerAndQuickDates
                startDate={selectedDates !== null ? moment(selectedDates?.startDate)
                  : selectedDates.startDate}
                endDate={selectedDates !== null ? moment(selectedDates?.endDate)
                  : selectedDates.endDate}
                onOrderHistoryDatesChange={(dates) => setSelectedDates(dates)}
              />
              <Button
                style={{
                  width: 150, height: 56, marginLeft: 20, marginRight: 20,
                }}
                color="secondary"
                variant="contained"
                onClick={() => getInventorySales()}
              >
                Update
              </Button>
            </div>
            <MaterialTable
              data={orders}
              title="Pitchbooking Inventory Sales"
              style={{ zIndex: 0 }}
              icons={{
                NextPage: () => <ChevronRight />,
                PreviousPage: () => <ChevronLeft />,
                DetailPanel: () => <ChevronRight />,
                Search: () => <Search />,
                Export: () => <SaveAlt />,
                ResetSearch: () => <Clear />,
                SortArrow: () => <UnfoldMoreIcon style={{ fill: 'var(--accent)' }} />,
              }}
              columns={[
                {
                  title: 'Category Name',
                  field: 'categoryName',
                  render: (row) => (
                    <p>{row.categoryName}</p>
                  ),
                },
                {
                  title: 'Quantity Sold',
                  render: (row) => (
                    <p>
                      {row.totalQuantity}
                    </p>
                  ),
                },
                {
                  title: 'Total',
                  render: (row) => (
                    <p>
                      {getCurrencySymbol(currency)}
                      {row?.totalCost?.toFixed(2)}
                    </p>
                  ),
                },
              ]}
              detailPanel={(rowData) => (
                <div style={{
                  padding: '20px',
                  backgroundColor: '#f9f9f9',
                  border: '1px solid #e0e0e0',
                  borderRadius: '5px',
                }}
                >
                  <Table>
                    <TableHead>
                      <TableCell> Item</TableCell>
                      <TableCell>Quantity Sold</TableCell>
                      <TableCell>Total</TableCell>
                    </TableHead>
                    {rowData.items.map((item) => (
                      <TableRow>
                        <TableCell>{item.addonName}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          {getCurrencySymbol(currency)}
                          {item.totalCost.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </div>
              )}
              options={{
                search: true,
                selection: false,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                exportButton: false,
                exportFileName: 'Pitchbooking POS Orders',
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastPageButtons: false,
                showTitle: false,
                headerStyle: {
                  backgroundColor: '#efefef',
                  fontWeight: 'bold',
                },
              }}
              localization={{
                toolbar: {
                  exportTitle: 'Download',
                },
              }}
            />
          </Paper>
        </>
        )}
      </Paper>
    </div>
  );
};

export default OrderHistory;
