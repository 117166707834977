/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Card } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { productBreakdownConstants, subNonSubBreakdownConstants, paymentBreakdownConstants } from './constants';

// Utility function to format the percentage
const formatPercentage = (total, totalOrderAmount) => {
  if (totalOrderAmount === 0) {
    return '';
  }

  const percentage = (total / totalOrderAmount) * 100;

  if (percentage === 0 || percentage === 100) {
    return `${percentage}%`;
  }

  return `${percentage.toFixed(2)}%`;
};

export const BreakdownCard = ({
  title, count, type, total, currencySym, totalOrderAmount, componentType,
}) => {
  let useConstant;

  // Determine which constants to use based on componentType
  if (componentType === 'subNonSub') {
    useConstant = subNonSubBreakdownConstants;
  } else if (componentType === 'methods') {
    useConstant = paymentBreakdownConstants;
  } else {
    useConstant = productBreakdownConstants;
  }

  const handleLabel = (type) => {
    if (type === 'pos' || type === 'invoice' || type === 'servicefee') {
      return useConstant[type]?.label;
    }
    return title;
  };

  return (
    <Card
      style={{
        padding: '1rem',
        backgroundColor: '#fff',
        border: `1px solid ${useConstant[type]?.color ?? useConstant.OTHER}`,
        borderRadius: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Label */}
          <Typography
            variant="body2"
            style={{
              flex: 1,
              fontWeight: '600',
              marginBottom: '0.5rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {handleLabel(type)}
          </Typography>
          <div style={{ flex: '0 0 auto' }}>
            {useConstant[type]?.icon}
          </div>
        </div>
        {count && (
          <div style={{ marginTop: '-0.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {count && (
                <Typography variant="body2" style={{ fontWeight: '600', marginBottom: '0.5rem' }}>
                  {count}
                </Typography>
              )}
            </div>

            {/* Percentage */}
            {totalOrderAmount && (
              <Typography variant="caption" style={{ fontWeight: '600', color: useConstant.OTHER }}>
                {formatPercentage(total, totalOrderAmount)}
              </Typography>
            )}
          </div>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {total && (
          <Typography
            variant="h6"
            style={{
              display: 'flex', alignItems: 'center', gap: '0.25rem', fontSize: '1.1rem',
            }}
          >
            <NumberFormat displayType="text" thousandSeparator prefix={currencySym} value={total} />
          </Typography>
        )}
      </div>
    </Card>
  );
};
