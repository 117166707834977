import * as yup from 'yup';

export const bookingModifierColors = {
  facility: {
    border: '2px solid rgb(124, 177, 223, 0.6)',
    backgroundColor: 'rgb(157, 196, 231, 0.4)',
    color: 'black',
  },
  ageGroup: {
    backgroundColor: 'rgb(130, 224, 170, 0.4)',
    border: '2px solid rgb(39, 174, 96, 0.6)',
    color: 'black',
  },
  event: {
    backgroundColor: 'rgb(71, 182, 255, 0.4)',
    border: '2px solid rgb(31, 165, 255, 0.6)',
    color: 'black',
  },
  misc: {
    backgroundColor: 'rgb(206, 206, 206, 0.6)',
    border: '2px solid #C2C2C2',
    color: 'black',
  },
};

export const bookingModifierTypes = [
  { value: 'AGE_GROUP', label: 'Age Group' },
  { value: 'event', label: 'Event' },
];

export const visibleTooltipString = 'When a booking modifier is set to visible it will be able to be selected by a booker on the public booking site. If it is set to private then it will only be able to be set by a facility manager.';

export const allowedDurations = [30, 60, 90, 120, 150, 180, 240, 300, 360, 420, 480, 540, 600];

export const allowedUnits = [1, 2, 3, 4, 5, 6, 7, 8];

export const durationsAlertText = `Allowed Durations: are the booking durations that you allow your customers to book, 
if they select this booking modifier. e.g. if you select 120 minutes, your customers will only have this option.`;

export const unitsAlertText = `Number of Units: refers to the number of sub facilities/splits that you want available to your customer,
e.g. if your facility splits into 4 quarters, by selecting 1, you are only allowing your customer to book a quarter,
if they select this booking modifier. This works in the same way if your facility has multiple sub facilities.`;

export const VALIDATION_SCHEMA = yup.object().shape({
  isEditing: yup.boolean(),
  name: yup.string().required('Booking modifer name is required'),
  type: yup.string().required('Booking modifier type is required'),
  facilities: yup.array().when('$isEditing', {
    is: true,
    then: yup.array().nullable(),
    otherwise: yup.array().min(1, 'Please select at least one facility').required('Please select at least one facility'),
  }),
  timingDurationsVisible: yup.boolean(),
  timingUnitsVisible: yup.boolean(),
  overrides: yup.object().shape({
    timingConfiguration: yup.object().shape({
      allowedDurations: yup.array().when('$timingDurationsVisible', {
        is: true,
        then: yup.array().min(1, 'Please select at least one duration').required('Please select at least one duration'),
        otherwise: yup.array().nullable(),
      }),
    }),
    allowedUnits: yup.array().when('$timingUnitsVisible', {
      is: true,
      then: yup.array().min(1, 'Please select at least one unit').required('Please select at least one unit'),
      otherwise: yup.array().nullable(),
    }),
  }).nullable(),
});
