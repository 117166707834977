/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import {
  convertFromRaw, EditorState, convertToRaw, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import MenuHeading from '../../../components/MenuHeading';
import AddTagDialog from '../../../components/AddTagDialog';
import * as tagActions from '../../../reducers/tagsReducer';
import { editLeagueDetails } from '../../../services/leaguesServices';
import useMobile from '../../../hooks/useMobile';

const validationSchema = Yup.object({
  name: Yup.string().required('League name is required'),
  description: Yup.string().test(
    'is-valid-description',
    'Description cannot be empty',
    (value) => {
      if (!value) return false;
      try {
        const contentState = convertFromRaw(JSON.parse(value));
        return contentState.hasText();
      } catch (e) {
        return false;
      }
    },
  ),
  maxTeams: Yup.number().moreThan(3, 'You need to have at least 4 teams to create a league'),
});

const EditLeagueDialog = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const league = useSelector((state) => state.leagues.league);
  const companyTags = useSelector((state) => state.tags.companyTags);
  const selectedTags = useSelector((state) => state.tags.selectedTags);
  const filteredTags = companyTags ? companyTags.filter((tag) => tag.category === 'LEAGUE_TYPE') : [];
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState('');

  const [editorState, setEditorState] = useState(() => {
    if (league?.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(league.description));
        return EditorState.createWithContent(contentState);
      } catch (e) {
        console.error('Error parsing description:', e);
        return EditorState.createEmpty();
      }
    }
    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (league?.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(league.description));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (e) {
        console.error('Error parsing description:', e);
      }
    }
  }, [league?.description]);

  const handleUpdateLeagueTags = (league) => {
    const leagueId = league.id;
    dispatch(tagActions.updateLeagueTags(leagueId));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError('');

    if (league?.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(league.description));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (e) {
        console.error('Error parsing description:', e);
        setEditorState(EditorState.createEmpty());
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: league?.name || '',
          description: league?.description || '',
          maxTeams: league?.maxTeams || 4,
          closingDate: league?.closingDate || moment().format('YYYY-MM-DD HH:mm'),
          visible: league?.visible || false,
          additionalInfo: league?.additionalInfo || null,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            if (!editorState.getCurrentContent().hasText()) {
              setError('Description cannot be empty');
              return;
            }

            const edit = await editLeagueDetails(league.id, values);
            if (edit.status === 200) {
              dispatch(sessionActions.updateSuccessfulSnackbar('Your league has been updated successfully!', true));
              handleClose();
            }
          } catch (err) {
            console.error(err);
            setError('An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at support@pitchbooking.com.');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form>
            <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
              Edit League
            </Button>
            <Dialog
              open={open}
              fullScreen={isMobile}
              fullWidth
              onClose={handleClose}
            >
              <ConditionallyVisible condition={error}>
                <Alert severity="error" style={{ margin: '1rem 2rem' }}>
                  {error}
                </Alert>
              </ConditionallyVisible>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle>
                  Edit League
                </DialogTitle>

                <DialogActions variant="none">
                  <CloseDialogIcon onClick={handleClose} />
                </DialogActions>
              </div>
              <DialogContent>
                {/* Title */}
                <MenuHeading title="League Title" />
                <PBInput
                  id="name"
                  type="text"
                  variant="outlined"
                  value={values.name}
                  isError={touched.name && Boolean(errors.name)}
                  errorMessage={touched.name && errors.name}
                  onChange={handleChange}
                />

                {/* Description */}
                <MenuHeading title="Description" />
                <div style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  padding: '6px 8px',
                  borderColor: touched.description && errors.description
                    ? '#f44336'
                    : 'rgba(0, 0, 0, 0.23)',
                }}
                >
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={(newState) => {
                      setEditorState(newState);
                      const contentState = newState.getCurrentContent();
                      const raw = convertToRaw(contentState);
                      setFieldValue('description', JSON.stringify(raw));
                      if (contentState.hasText() && error) {
                        setError('');
                      }
                    }}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'link', 'history'],
                      inline: {
                        options: ['bold', 'italic'],
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                      },
                    }}
                    placeholder="Enter your about description here..."
                    stripPastedStyles
                  />
                  {touched.description && errors.description && (
                    <div style={{ color: '#f44336', fontSize: '0.75rem', marginTop: '3px' }}>
                      {errors.description}
                    </div>
                  )}
                </div>

                {/* Additional Info */}
                {values.additionalInfo && (
                  <>
                    <MenuHeading title="Additional Info" />
                    <PBInput
                      id="additionalInfo"
                      type="textarea"
                      rows="4"
                      multiline
                      variant="outlined"
                      value={values.additionalInfo}
                      isError={touched.additionalInfo && Boolean(errors.additionalInfo)}
                      errorMessage={touched.additionalInfo && errors.additionalInfo}
                      onChange={handleChange}
                    />
                  </>
                )}

                {/* Max Teams */}
                <MenuHeading title="Maximum Number of Teams" />
                <PBInput
                  id="maxTeams"
                  variant="outlined"
                  isError={touched.maxTeams && Boolean(errors.maxTeams)}
                  errorMessage={touched.maxTeams && errors.maxTeams}
                  value={values.maxTeams}
                  onChange={handleChange}
                />

                {/* Closing Date */}
                <MenuHeading title="Closing Date" />
                <SingleDatePicker
                  date={moment(values.closingDate)}
                  onDateChange={(date) => setFieldValue(
                    'closingDate', moment(date).format('YYYY-MM-DD HH:mm'),
                  )}
                  style={{ width: 200 }}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  displayFormat="ddd, DD/MM/YY"
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  fullWidth
                  noBorder
                />
                <MenuHeading title="Publicly visible" />
                <Switch
                  id="visible"
                  value={values.visible}
                  checked={values.visible}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                {/* <AddTagDialog
                  tags={filteredTags}
                  selectedTags={league.tags}
                  currentSelectedTags={selectedTags}
                  addTag={(value) => dispatch(tagActions.addToSelectedTags(value))}
                  removeTag={(value) => dispatch(tagActions.removeFromSelectedTags(value))}
                  addTags={() => handleUpdateLeagueTags(league)}
                  setSelectedTags={(tags) => dispatch(tagActions.updateSelectedTags(tags))}
                  tagRelation="League"
                /> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                  Close
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditLeagueDialog;
