/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { VerifiedUserTwoTone } from '@material-ui/icons/';

/**
 * VerifiedPbUserCheck
 *
 * This component displays a user's name with a special verification badge if they are a
 * Pitchbooking administrator (determined by checking if their email contains "@pitchbooking").
 *
 * - If the user is a Pitchbooking administrator, their name is wrapped in a tooltip with an icon
 *   indicating verification.
 * - If the user is not an administrator, their name is displayed as a clickable link
 *   that navigates to their profile page.
 * - If no user is provided, a warning is logged, and nothing is rendered.
 *
 * Props:
 * @param {Object} user - The user object.
 * @param {String} startLabel - Optional text to display before the user's name.
 *
 */

export const VerifiedPbUserCheck = ({ user, startLabel }) => {
  if (!user) {
    console.warn('No user provided');
    return null;
  }

  return (
    <>
      {user.email.includes('@pitchbooking')
        ? (
          <Tooltip title="Pitchbooking Administrator" arrow>
            <Typography
              variant="p"
              style={{
                fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '0.175rem', cursor: 'pointer',
              }}
            >
              {`${startLabel ?? ''} ${user.firstName} ${user.lastName}`}
              <VerifiedUserTwoTone style={{ fontSize: '1rem', color: '#0CD48B' }} />
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="p" component="a" href={`/users/${user.userId}`} target="_blank">
            {`${startLabel ?? ''} ${user.firstName} ${user.lastName}`}
          </Typography>
        )}
    </>
  );
};
