import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import PayoutReconciliationDialog from './PayoutReconciliationDialog';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import RecalculatePayoutButton from './RecalculatePayoutButton';

const payoutReconciliationButton = (props) => {
  const { payout, hideStatus } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {payout.reconciled && <PayoutReconciliationDialog payout={payout} />}

      {payout.reconciled === false
        ? (
          <>
            {!hideStatus && (
              <Tooltip title="Payout is pending. Your paid breakdown will be available soon,
              please contact your account manager if you have any queries."
              >
                <div className="pendingTag">Pending</div>
              </Tooltip>
            )}

            <PitchbookingUserOnly>
              <RecalculatePayoutButton payoutId={payout.id} />
            </PitchbookingUserOnly>
          </>
        )
        : null}
    </div>
  );
};

payoutReconciliationButton.propTypes = {
  payout: PropTypes.number.isRequired,
  hideStatus: PropTypes.bool,
};

payoutReconciliationButton.defaultProps = {
  hideStatus: false,
};

export default payoutReconciliationButton;
