/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Tooltip, Chip, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { resendUserEmail } from '../../services/usersServices';
import { useCompany, useToast } from '../../hooks';

/**
 * Chip component that displays a status/text tag with optional resend email functionality
 * @returns {{
 * data: Object,
 * label: String,
 * colorScheme: String,
 * sendEmail: boolean
 * toolTipText: String,
 * emailType: String // This will need handled in the resendUserEmail endpoint switch statement
 * hideTooltip: boolean
 * }}
 */

export const StatusTag = ({
  data, label, colorScheme, sendEmail, toolTipText, emailType, hideTooltip,
}) => {
  const company = useCompany();
  const toast = useToast();
  const [isHovered, setIsHovered] = useState(false);
  const {
    color, borderColor, backgroundColor, hoverColor,
  } = colorScheme;

  const mutation = useMutation({
    mutationFn: async () => {
      const body = {
        emailType,
        data,
      };
      const res = await resendUserEmail(company.id, body);

      if (res.status !== 200) {
        throw new Error('Error resending invite');
      }

      return res.data;
    },
    onSuccess: (res) => {
      toast.trigger({
        type: 'success',
        message: res,
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error resending mail',
      });
    },
  });

  return (
    <Tooltip title={toolTipText || 'No tool tip text provided'} disableHoverListener={hideTooltip}>
      <Chip
        label={(
          <Typography
            variant="inherit"
            style={{
              display: 'flex', alignItems: 'center', color: `${color}`, fontWeight: '600',
            }}
          >
            {label || 'No label provided'}

            {sendEmail && (
              mutation.isLoading
                ? <CircularProgress size={20} style={{ marginLeft: '0.5rem', color: `${hoverColor}` }} />
                : (
                  <SendIcon
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    fontSize="small"
                    style={{ marginLeft: '0.5rem', cursor: 'pointer', color: isHovered ? `${borderColor}` : `${hoverColor}` }}
                    onClick={() => mutation.mutate()}
                  />
                )
            )}
          </Typography>
    )}
        style={{ border: `2px solid ${borderColor}`, backgroundColor: `${backgroundColor}`, width: '100%' }}
      />
    </Tooltip>
  );
};

StatusTag.propTypes = {
  data: PropTypes.shape({}),
  label: PropTypes.string,
  colorScheme: PropTypes.shape({
    color: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverColor: PropTypes.string,
  }),
  sendEmail: PropTypes.bool,
  toolTipText: PropTypes.string,
  emailType: PropTypes.string,
  hideTooltip: PropTypes.bool,
};

StatusTag.defaultProps = {
  data: {},
  label: '',
  colorScheme: {
    color: 'black',
    borderColor: 'rgb(173, 173, 173)',
    backgroundColor: 'rgb(206, 206, 206, 0.4)',
    hoverColor: 'black',
  },
  sendEmail: false,
  toolTipText: '',
  emailType: '',
  hideTooltip: false,
};
