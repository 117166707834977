import {
  takeEvery, call, put, select, all,
} from 'redux-saga/effects';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import moment from 'moment';
import {
  getBookingsService,
  getBookingService,
  updateBookingServices,
  editBookingAndCreationService,
  swapBookingsService,
  updateBookingAllocationServices,
} from '../services/bookingsServices';
import {
  getSubscriptionsReservationsService,
} from '../services/subscriptionsServices';
import * as bookingsActions from '../reducers/bookingsReducer';
import * as calendarActions from '../reducers/calendarReducer';
import { handleSagaError } from './helperSaga';

function* getBookingsSaga(companyId, action) {
  const { dates, status } = action;
  if (companyId) {
    try {
      yield put(bookingsActions.updateRequestedDates(dates));
      const response = yield call(getBookingsService, companyId, dates, status);
      if (!response.error) {
        yield put(bookingsActions.updateBookings(response.data));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getBookingsSaga');
    }
  }
}

function* requestBookingsAndSubscriptionsSaga(companyId, action) {
  const { options } = action;
  const {
    startDate, endDate, status, slotType, facilities,
  } = options;
  const dates = {
    fromDate: startDate,
    toDate: endDate,
  };

  if (companyId) {
    try {
      let bookingsResponse;
      let subscriptionsResponse;
      if (slotType === 'RESERVATION') {
        bookingsResponse = yield call(getBookingsService, companyId, dates, status, facilities);
        subscriptionsResponse = { data: [] };
      } else if (slotType === 'SUBSCRIPTION') {
        subscriptionsResponse = yield call(
          getSubscriptionsReservationsService, companyId, dates, facilities, status,
        );
        bookingsResponse = { data: [] };
      } else if (status !== 'CANCELLED') {
        [bookingsResponse, subscriptionsResponse] = yield all([
          call(getBookingsService, companyId, dates, status, facilities),
          call(getSubscriptionsReservationsService, companyId, dates, facilities),
        ]);
      } else {
        bookingsResponse = yield call(getBookingsService, companyId, dates, status, facilities);
        subscriptionsResponse = { data: [] };
      }
      const response = bookingsResponse.data.concat(subscriptionsResponse.data);
      if (!response.error) {
        yield put(bookingsActions.bookingsAndSubsciptionsSuccessful(response));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getBookingsSaga');
    }
  }
}

function* getBookingSaga(companyId, action) {
  const { reservationId } = action;
  if (companyId) {
    try {
      const response = yield call(getBookingService, companyId, reservationId);
      const newBookingResultsAllocations = response.data[0].type !== 'POS' ? response.data[0].allocations.filter((allocation) => allocation.status === 'ACTIVE') : response.data[0].allocations;
      const selectedRowShape = {
        ...response.data[0],
        slot: response.data[0].startTime,
        id: response.data[0].id
          ? response.data[0].id
          : response.data[0].subscriptionId,
        userId: response.data[0].user.id,
        allocations: response.data[0].allocations.concat(
          response.data[0].amenities,
        ),
      };

      yield put(bookingsActions.updateSelectedRows(selectedRowShape, true));
      const newBookingResults = {
        ...response.data[0],
        allocations: newBookingResultsAllocations,
      };
      if (!response.error) {
        yield put(bookingsActions.bookingRetrievalSuccessful(newBookingResults));
      } else { throw response; }
    } catch (error) {
      yield call(handleSagaError, error, 'getBookingSaga');
    }
  }
}

function* updateBookingSaga(companyId, bookingsAction) {
  const state = yield select();
  const { calendar } = state;
  try {
    const response = yield call(
      updateBookingServices,
      companyId,
      bookingsAction.reservationId,
      bookingsAction.reqBody,
    );
    if (!response.error) {
      yield put(bookingsActions.getBooking(bookingsAction.reservationId));
      yield put(sessionActions.updateSuccessfulSnackbar('Booking Updated Successfully', true));

      if (Object.keys(calendar.calendarUsage).length > 0) {
        yield put(calendarActions.getCalendarUsage());
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateBookingSaga');
    yield put(sessionActions.updateErrorSnackbar(`Booking Updated Failed : ${error}`, true));
  }
}

function* editBookingAndCreationSaga(companyId) {
  const state = yield select();
  const {
    facilities, reservation,
    basket, bookings,
  } = state;
  const { selectedFacility } = facilities;
  const {
    userDetailsForReservation, selectedPaymentStatus, selectedAgeGroup, selectedEvent,
  } = reservation;
  const { confirmedAllocations } = basket;
  const combinedNotes = `${userDetailsForReservation.adminNotes}`;
  const previousAllocationId = bookings.reservation.allocations[0].id;

  const resreservationId = bookings.reservation.id;
  const reqBody = {
    reservation: bookings.reservation,
    previousAllocationId,
    facilityId: selectedFacility.id,
    facilityName: selectedFacility.name,
    slots: confirmedAllocations,
    userEmailFor: userDetailsForReservation.email,
    ageGroupBookingModifierId: selectedAgeGroup,
    eventBookingModifierId: selectedEvent,
    facilityAccessInstructions: selectedFacility.accessInstructions,
    sport: selectedFacility.sport[0],
    paymentStatus: selectedPaymentStatus,
    requestToBook: false,
    notes: combinedNotes,
  };

  try {
    const response = yield call(
      editBookingAndCreationService,
      companyId,
      reqBody,
    );
    if (!response.error) {
      yield put(bookingsActions.getBooking(resreservationId));
      yield put(bookingsActions.toggleEditBookingDialog());
      yield put(sessionActions.updateSuccessfulSnackbar('Booking Updated Successfully', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'editBookingAndCreationSaga');
    yield put(sessionActions.updateErrorSnackbar(`Booking Updated Failed : ${error}`, true));
  }
}

function* swapBookings(action) {
  const { bookings, options } = action;
  try {
    const response = yield call(
      swapBookingsService,
      bookings,
    );

    if (!response.error) {
      yield put(bookingsActions.toggleSwapBookingDialog());
      yield put(bookingsActions.resetSelectedRows());
      yield put(bookingsActions.requestBookingsAndSubscriptions(options));
    } else { throw response; }
  } catch (err) {
    yield call(handleSagaError, err, 'editBookingAndCreationSaga');
    yield put(sessionActions.updateErrorSnackbar(`Booking Updated Failed : ${err}`, true));
  }
}

function* updateFacilityBooking(companyId, action) {
  try {
    const { booking, facilities } = yield select();
    const { bookingId, timeslots, resetRows } = action;

    const response = yield updateBookingAllocationServices(
      companyId,
      bookingId,
      {
        timeslots,
        pitchSplit: booking.selectedPitchSplit,
      },
    );

    if (!response.error) {
      yield put(sessionActions.updateSuccessfulSnackbar('Booking Updated Successfully', true));

      const { startTime, endTime } = timeslots.reduce((acc, curr) => {
        if (moment(curr.startTime).isBefore(acc.startTime)) {
          acc.startTime = curr.startTime;
        }

        if (moment(curr.endTime).add(1, 's').isAfter(acc.endTime)) {
          acc.endTime = curr.endTime.add(1, 's');
        }

        return acc;
      }, { startTime: timeslots[0].startTime, endTime: timeslots[0].endTime.add(1, 's') });

      yield put(bookingsActions.updateBookingsEditSuccess({
        message: 'Booking Updated Successfully',
        facility: facilities.companyFacilities.find((x) => timeslots[0].facilityId === x.id).name,
        date: moment(startTime).format('DD-MMM-YYYY'),
        startTime: moment(startTime).format('HH:mm'),
        endTime: moment(endTime).format('HH:mm'),
      }));
      if (resetRows) {
        yield put(bookingsActions.resetSelectedRows());
        window.location.reload();
      }
      // TODO: Do this also to refresh the calendar
      // yield put(bookingsActions.requestBookingsAndSubscriptions(options));
    } else { throw response; }
  } catch (err) {
    yield put(bookingsActions.updateBookingEditFailed(true));
    yield put(sessionActions.updateErrorSnackbar(`Failed to update Facility Booking : ${err.error}`, true));
  }
}

// WATCHERS
export function* bookingsWatcher(companyId) {
  yield takeEvery(bookingsActions.GET_BOOKINGS, getBookingsSaga, companyId);
}
export function* requestBookingsAndSubscriptionsWatcher(companyId) {
  yield takeEvery(bookingsActions.BOOKINGS_AND_SUBSCRIPTIONS_RETRIEVAL_REQUESTED,
    requestBookingsAndSubscriptionsSaga, companyId);
}
// Singular booking
export function* bookingWatcher(companyId) {
  yield takeEvery(bookingsActions.BOOKING_RETRIEVAL_REQUESTED, getBookingSaga, companyId);
}
export function* updateBookingWatcher(companyId) {
  yield takeEvery(bookingsActions.UPDATE_BOOKING_REQUESTED, updateBookingSaga, companyId);
}

// EDIT BOOKING AND CREATION
export function* editBookingAndCreationWatcher(companyId) {
  yield takeEvery(
    bookingsActions.BOOKING_EDIT_AND_CREATION_REQUESTED,
    editBookingAndCreationSaga, companyId,
  );
}

export function* swapBookingsWatcher() {
  yield takeEvery(bookingsActions.SWAP_BOOKINGS_REQUESTED, swapBookings);
}

export function* updateFacilityBookingWatcher(companyId) {
  yield takeEvery(
    bookingsActions.UPDATE_FACILITY_BOOKING_REQUESTED,
    updateFacilityBooking,
    companyId,
  );
}
