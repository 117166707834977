/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { DayPickerSingleDateController } from 'react-dates';
import { useCompany, useMobile, useToast } from '../../../../hooks';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';
import { editSubscriptionService } from '../../../../services/subscriptionsServices';

const validationSchema = Yup.object().shape({
  price: Yup.number().min(1, 'Enter a valid price').required('Price is required'),
  dateOfEffect: Yup.date().required('Date of effect is required'),
});

export const SubscriptionEditPriceDialog = ({
  isOpen, onClose, onSuccess, subscription,
}) => {
  const { currencySym } = useCompany();
  const queryClient = useQueryClient();
  const isMobile = useMobile();
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: async (data) => {
      const { price, dateOfEffect } = data;

      if (dateOfEffect) {
        queryClient.setQueryData(['subscription', subscription.id], (oldData) => ({
          ...oldData,
          priceChange: {
            amount: subscription.amount,
            dateOfEffect,
          },
        }));
      }

      const res = await editSubscriptionService(subscription.companyId, subscription.id, {
        newPrice: price * 100,
        dateOfEffect: moment(dateOfEffect).tz(subscription.company.timezone).endOf('day').format(),
      });

      if (res.status !== 200) {
        throw new Error(res.data);
      }

      return res.data;
    },
    onError: (error) => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      toast.trigger({
        type: 'error',
        message: 'Failed to update the subscription price!',
      });
      console.error(error);
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: 'Subscription price updated successfully',
      });
      queryClient.invalidateQueries(['subscription', subscription.id]);
      onClose();
      onSuccess?.();
    },
  });

  const { accessRestriction } = subscription;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>Edit Subscription Price</DialogTitle>
        <DialogActions variant="none">
          <CloseDialogIcon onClick={onClose} />
        </DialogActions>
      </div>

      <Formik
        initialValues={{
          price: subscription.amount / 100,
          dateOfEffect: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => mutation.mutate(data)}
      >
        {({
          values, setFieldValue, getFieldProps, handleSubmit, touched, errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
            >
              <PBInput
                label="Price"
                id="price"
                type="number"
                isError={touched.price && errors.price}
                errorMessage={touched.price && errors.price}
                {...getFieldProps('price')}
              />

              <>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                >
                  <DayPickerSingleDateController
                    isDayHighlighted={(day) => moment(values.dateOfEffect).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')}
                    isDayBlocked={(day) => {
                      // Is the day a subscription day?
                      if (moment(day).weekday() !== accessRestriction.weekday) return true;

                      // Is the day before the valid from date?
                      if (moment(day).isBefore(moment(accessRestriction.validFrom).subtract(1, 'day'))) return true;

                      // Is the day before the valid from date?
                      if (moment(day).isBefore(moment())) return true;

                      // Is the day after the valid to date?
                      if (moment(day).isAfter(moment(accessRestriction.validTo).add(1, 'day'))) return true;

                      return false;
                    }}
                    onDateChange={(date) => {
                      const formattedDate = date.format('YYYY-MM-DD');
                      setFieldValue('dateOfEffect', formattedDate);
                    }}
                  />
                </div>
                {errors.dateOfEffect && (
                <Typography variant="body2" color="error">
                  {errors.dateOfEffect}
                </Typography>
                )}

                <Typography variant="body2">
                  The user will continue to be charged at the current rate until the new price
                  takes effect. We will send them an email letting them know about the change.
                </Typography>
              </>
              <Typography variant="body2" color="error">
                Please ensure you have discussed the price change with the user prior to
                updating this subscription.
              </Typography>

            </DialogContent>

            <DialogActions>
              <Button
                type="button"
                onClick={onClose}
                color="secondary"
                autoFocus
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={mutation.isLoading}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
