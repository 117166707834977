import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { AccountCircleTwoTone } from '@material-ui/icons';
import * as companyUsersActions from '../../../reducers/companyUsersReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import AddTeamMemberForm from './AddTeamMemberForm';

const initialState = () => ({
  open: false,
});

class AddTeamMemberDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState() };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ ...initialState() });
  };

  addTeamMember = (values) => {
    const { addUserToCompany } = this.props;
    const {
      firstName, lastName, email, selectedSite, receivesEmails, role, jobRole, visibleContact,
      publicDisplayName,
    } = values;

    const reqBody = {
      firstName,
      lastName,
      email,
      selectedSite,
      receivesEmails,
      role,
      jobRole,
      visibleContact,
      publicDisplayName,
    };
    addUserToCompany(reqBody);
    this.handleRequestClose();
  }

  render() {
    const {
      sites, title, roles, currentUser, companyTitles, isAgreementPage,
    } = this.props;

    const isPBAdmin = currentUser && currentUser.managerAccess.some((access) => access.role.type === 'PITCHBOOKING_INTERNAL');
    const {
      isLoading, open,
    } = this.state;

    const initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      selectedSite: 'all',
      receivesEmails: false,
      role: null,
      jobRole: 'NOT SPECIFIED',
      visibleContact: false,
      publicDisplayName: '',
    };
    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    const siteOptions = sites ? [{ id: 'all', name: 'All Sites' }, ...sites] : [];

    const isMobile = window.innerWidth < 768;

    return (
      <div>
        <Button
          variant={isAgreementPage ? 'outlined' : 'contained'}
          color="primary"
          onClick={() => this.setState({ open: true })}
        >
          <div style={{
            display: 'flex', alignContent: 'center', gap: '0.5rem', alignItems: 'center',
          }}
          >
            {isAgreementPage ? <AccountCircleTwoTone /> : ''}
            {title || 'Add New Admin'}
          </div>
        </Button>

        <Dialog
          open={open}
          onRequestClose={this.handleRequestClose}
          maxWidth={false}
          fullScreen={isMobile}
        >
          <LinearProgress
            color="primary"
            variant={isLoading ? 'determinate' : 'indeterminate'}
            value={100}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle color="primary">Add New Dashboard Admin</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              dirty,
              errors,
              handleBlur,
              handleChange,
              isValid,
              touched,
              setFieldValue,
              values,
            }) => (
              <Form>
                <DialogContent
                  style={{
                    minWidth: 700,
                    minHeight: 300,
                  }}
                >
                  <AddTeamMemberForm
                    isPBAdmin={isPBAdmin}
                    values={values}
                    siteOptions={siteOptions}
                    handleChange={handleChange}
                    companyTitles={companyTitles}
                    roles={roles}
                    setFieldValue={setFieldValue}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleRequestClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    disabled={!(isValid && dirty)}
                    onClick={() => this.addTeamMember(values)}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Invite
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

AddTeamMemberDialog.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyTitles: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.shape().isRequired,
  addUserToCompany: PropTypes.func.isRequired,
  title: PropTypes.string,
  isAgreementPage: PropTypes.bool,
};

AddTeamMemberDialog.defaultProps = {
  title: '',
  companyTitles: ['Finance', 'Operations', 'Support', 'Manager', 'Technical', 'Owner'],
  isAgreementPage: false,
};

function mapStateToProps(state) {
  const { memberships, companies, users } = state;
  const { sites } = companies.companyInfo;
  const { companyUsers } = state.companyUsers;
  const { roles } = state.companyUsers;
  const { currentUser } = users;
  return {
    memberships,
    sites,
    roles,
    companyUsers,
    currentUser,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addUserToCompany: (reqBody) => dispatch(companyUsersActions.addUserToCompany(reqBody)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AddTeamMemberDialog);
