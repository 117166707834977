/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import PricingAddRuleDialogSection from './PricingAddRuleDialogSection';
import * as pricingActions from '../../reducers/pricingReducer';

export const DuplicatePriceGroupDialog = (props) => {
  const {
    open,
    onClose,
    row,
    membershipTag,
    facilityTag,
    companyMemberships,
  } = props;

  const dispatch = useDispatch();
  const priceGroup = useSelector((state) => state.pricing.priceGroup);
  const { endDate, startDate, membership } = priceGroup;

  const [isDirty, setIsDirty] = useState(false);

  // When the dialog opens, update the store with the selected row
  useEffect(() => {
    if (open) {
      dispatch(pricingActions.updatePriceGroupStore({ ...row }));
      setIsDirty(false); // Reset dirty state when dialog opens
    }
  }, [open, row, dispatch]);

  const handleClose = () => {
    dispatch(pricingActions.resetPriceGroupCreation());
    onClose();
  };

  const handleDuplicate = () => {
    dispatch(pricingActions.requestPriceGroupDuplicate());
    dispatch(pricingActions.resetPriceGroupCreation());
    onClose();
  };

  const handleUpdate = (data) => {
    dispatch(pricingActions.updatePriceGroupStore(data));
    setIsDirty(true); // Mark as dirty when any field is updated
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Duplicate selected price group</DialogTitle>
      <DialogContent style={{ width: '600px' }}>
        <div style={{ marginBottom: '20px' }}>
          This dialog allows you to duplicate an existing price group
        </div>
        <DialogTitle style={{ paddingLeft: 0 }}>Facilities</DialogTitle>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '40px' }}>
          {row.facilities.map((f) => facilityTag(f))}
        </div>
        <ConditionallyVisible condition={row.membership}>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '40px' }}>
            <div style={{ width: '100%' }}>
              Memberships
            </div>
            {membershipTag(row)}
          </div>
        </ConditionallyVisible>
        <DialogTitle style={{ paddingLeft: 0 }}>Additional Changes</DialogTitle>
        <div>
          <PricingAddRuleDialogSection
            title="Membership"
            parameter="membership"
            value={membership}
            membership={membership}
            memberships={companyMemberships}
            onUpdate={handleUpdate}
          />
          <Divider />
          <PricingAddRuleDialogSection
            title="Start Date"
            parameter="startDate"
            value={startDate}
            onUpdate={handleUpdate}
          />
          <Divider />
          <PricingAddRuleDialogSection
            title="End Date"
            parameter="endDate"
            value={endDate}
            onUpdate={handleUpdate}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Close
        </Button>
        <Button
          onClick={handleDuplicate}
          color="primary"
          variant="contained"
          disabled={!isDirty}
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
