/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { Form, Formik } from 'formik';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useSelector, useDispatch } from 'react-redux';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { Alert } from '@material-ui/lab';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { useMutation } from '@tanstack/react-query';
import {
  bookingModifierTypes, visibleTooltipString, VALIDATION_SCHEMA, allowedDurations,
  allowedUnits, durationsAlertText, unitsAlertText,
} from './constants';
import { createCompanyBookingModifiersService, requestBookingModifiersSaveService } from '../../../services/bookingModifiersServices';
import { useToast } from '../../../hooks';
import * as actions from '../../../reducers/bookingModifiersReducer';

// Helper function to format duration labels
const formatDurationLabel = (duration) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : ''}`;
  }
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
};

export const BookingModifierDialog = ({ bookingModifier, isEditing, onUpdate }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDurationsAlert, setShowDurationsAlert] = useState(false);
  const [showUnitsAlert, setShowUnitsAlert] = useState(false);
  const company = useSelector((state) => state.companies.companyInfo);
  const facilities = useSelector((state) => state.facilities.companyFacilities);
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (values) => {
      const data = {
        ...values,
        id: isEditing ? bookingModifier.id : null,
      };

      const res = isEditing
        ? await requestBookingModifiersSaveService(company.id, data)
        : await createCompanyBookingModifiersService(company.id, data);

      if (res.status !== 200) {
        throw new Error('Error Updating Booking Modifier');
      }

      return { ...res.data, type: values.type };
    },
    onSuccess: (data) => {
      toast.trigger({
        type: 'success',
        message: `Booking Modifier ${!isEditing ? 'Created' : 'Edited'} Successfully`,
      });
      setIsOpen(false);
      dispatch(actions.getCompanyBookingModifiers());
      if (isEditing) {
        onUpdate?.(data);
      }
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error Updating Booking Modifier',
      });
    },
  });

  return (
    <>
      <Button variant="contained" color={!isEditing ? 'primary' : 'secondary'} onClick={() => setIsOpen(!isOpen)}>
        {!isEditing ? (
          <Typography
            style={{
              display: 'flex', alignItems: 'center', gap: '0.5rem', fontWeight: '600',
            }}
            variant="button"
          >
            Create Booking Modifier
          </Typography>
        ) : (
          <Typography
            style={{
              display: 'flex', alignItems: 'center', gap: '0.5rem', fontWeight: '600',
            }}
            variant="button"
          >
            <EditTwoToneIcon />
            Edit
          </Typography>
        )}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem',
        }}
        >
          {/* Title and Close */}
          <div style={{
            display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              {!isEditing ? 'Create a New Booking Modifier' : 'Edit Booking Modifier'}
            </Typography>
            <Button onClick={() => setIsOpen(false)}>
              <CancelTwoToneIcon style={{ justifySelf: 'flex-end' }} />
            </Button>
          </div>

          <Formik
            validationSchema={VALIDATION_SCHEMA}
            initialValues={{
              name: isEditing ? bookingModifier.name : '',
              type: isEditing ? bookingModifier.type : '',
              visible: isEditing ? bookingModifier.visible : true,
              facilities: isEditing ? bookingModifier.facilities : [],
              overrides: isEditing && bookingModifier.overrides
                ? bookingModifier.overrides
                : null,
              timingDurationsVisible: !!(
                isEditing
                && bookingModifier?.overrides?.timingConfiguration?.allowedDurations?.length > 0
              ),
              timingUnitsVisible: !!(
                isEditing
                && bookingModifier?.overrides?.allowedUnits?.length > 0
              ),
              isEditing,
            }}
            onSubmit={(values) => {
              mutation.mutate(values);
            }}
          >
            {({
              values, errors, touched, handleChange,
              handleBlur, handleSubmit, setFieldValue, getFieldProps,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  {/* Booking Modifier Name */}
                  <PBInput
                    id="name"
                    type="text"
                    label="Booking Modifier Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    isError={touched.name && errors.name}
                    errorMessage={touched.name && errors.name}
                  />

                  {/* Booking Modifier Type */}
                  {!isEditing && (
                    <PBInput
                      id="type"
                      type="select"
                      label="Booking Modifier Type"
                      options={bookingModifierTypes}
                      value={values.type}
                      onChange={handleChange}
                      required
                      onBlur={handleBlur}
                      isError={touched.type && errors.type}
                      errorMessage={touched.type && errors.type}
                    />
                  )}

                  {/* Booking Modifier Facilities */}
                  {!isEditing && (
                    <PBInput
                      id="facilities"
                      type="select"
                      label="Facilities Where Booking Modifier Applies"
                      multiple
                      required
                      options={facilities.map((facility) => ({
                        value: facility,
                        label: facility.name,
                      }))}
                      {...getFieldProps('facilities')}
                      isError={touched.facilities && errors.facilities}
                      errorMessage={touched.facilities && errors.facilities}
                    />
                  )}

                  {/* Booking Modifier Visibility */}
                  {showAlert && (
                  <Alert severity="info">
                    {visibleTooltipString}
                  </Alert>
                  )}
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                  }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                      }}
                    >
                      Is this Booking Modifier Visible to Bookers?
                      <HelpTwoToneIcon
                        onClick={() => setShowAlert(!showAlert)}
                        style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showAlert ? 'var(--accent)' : '' }}
                      />
                    </Typography>
                    <PBInput
                      id="visible"
                      type="toggle"
                      value={values.visible}
                      onChange={(e) => setFieldValue('visible', e.target.checked)}
                    />

                  </div>

                  {/* Durations config toggle */}
                  {values.type === 'event' && (
                    <>
                        {showDurationsAlert && (
                        <Alert severity="info">
                          {durationsAlertText}
                        </Alert>
                        )}
                      <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                      }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                          }}
                        >
                          Do you want to restrict Durations?
                          <HelpTwoToneIcon
                            onClick={() => setShowDurationsAlert(!showDurationsAlert)}
                            style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showDurationsAlert ? 'var(--accent)' : '' }}
                          />
                        </Typography>
                        <PBInput
                          id="timingDurationsVisible"
                          type="toggle"
                          {...getFieldProps('timingDurationsVisible')}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.checked) {
                              setFieldValue('overrides', {
                                timingConfiguration: {
                                  allowedDurations: [],
                                },
                                allowedUnits: values.overrides?.allowedUnits || [],
                              });
                            } else {
                              setFieldValue('overrides', {
                                timingConfiguration: {
                                  allowedDurations: [],
                                },
                                allowedUnits: values.overrides?.allowedUnits || null,
                              });
                            }
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* Allowed Durations */}
                  {values.timingDurationsVisible && (
                  <PBInput
                    id="overrides.timingConfiguration.allowedDurations"
                    type="select"
                    label="Allowed Durations"
                    multiple
                    options={
                      allowedDurations.map(
                        (duration) => ({
                          value: duration, label: formatDurationLabel(duration),
                        }),
                      )
                    }
                    required
                    {...getFieldProps('overrides.timingConfiguration.allowedDurations')}
                    isError={touched.overrides?.timingConfiguration?.allowedDurations
                          && errors.overrides?.timingConfiguration?.allowedDurations}
                    errorMessage={touched.overrides?.timingConfiguration?.allowedDurations
                          && errors.overrides?.timingConfiguration?.allowedDurations}
                  />
                  )}

                  {/* Units config toggle */}
                  {values.type === 'event' && (
                    <>
                        {showUnitsAlert && (
                        <Alert severity="info">
                          {unitsAlertText}
                        </Alert>
                        )}
                      <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                      }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                          }}
                        >
                          Do you want to restrict Unit(s)?
                          <HelpTwoToneIcon
                            onClick={() => setShowUnitsAlert(!showUnitsAlert)}
                            style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showUnitsAlert ? 'var(--accent)' : '' }}
                          />
                        </Typography>
                        <PBInput
                          id="timingUnitsVisible"
                          type="toggle"
                          {...getFieldProps('timingUnitsVisible')}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.checked) {
                              setFieldValue('overrides', {
                                timingConfiguration: {
                                  allowedDurations:
                                    values.overrides?.timingConfiguration?.allowedDurations
                                    || [],
                                },
                                allowedUnits: [],
                              });
                            } else {
                              setFieldValue('overrides', {
                                timingConfiguration: {
                                  allowedDurations:
                                    values.overrides?.timingConfiguration?.allowedDurations
                                    || [],
                                },
                                allowedUnits: null,
                              });
                            }
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* Allowed Units */}
                  {values.timingUnitsVisible && (
                    <PBInput
                      id="overrides.allowedUnits"
                      type="select"
                      label="Number of Units"
                      multiple
                      options={allowedUnits.map((units) => ({ value: units, label: units === 1 ? `${units} Unit` : `${units} Units` }))}
                      required
                      {...getFieldProps('overrides.allowedUnits')}
                      isError={errors.overrides?.allowedUnits && touched.overrides?.allowedUnits}
                      errorMessage={errors.overrides?.allowedUnits}
                    />
                  )}

                  <div style={{
                    display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem',
                  }}
                  >
                    <Button variant="contained" onClick={() => setIsOpen(false)} disabled={mutation.isLoading}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit" disabled={mutation.isLoading}>Submit</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};
