export const invoiceStatusColors = {
  PAID: {
    colorScheme: {
      color: 'rgb(21, 122, 73)',
      borderColor: 'rgb(30, 174, 104)',
      backgroundColor: 'rgb(168, 240, 205)',
    },
  },
  UNPAID: {
    colorScheme: {
      color: 'rgb(144, 46, 39)',
      borderColor: 'rgb(172, 55, 47)',
      backgroundColor: 'rgb(233, 179, 175)',
    },
  },
  PENDING: {
    colorScheme: {
      color: 'rgb(0, 73, 122)',
      borderColor: 'rgb(31, 165, 255, 0.8)',
      backgroundColor: 'rgb(71, 182, 255, 0.3)',
    },
  },
  VOID: {
    colorScheme: {
      color: 'rgb(0, 0, 0)',
      borderColor: 'rgb(173, 173, 173)',
      backgroundColor: 'rgb(206, 206, 206, 0.4)',
    },
  },
};

export const payoutStatusColors = {
  paid: {
    colorScheme: {
      color: 'rgb(21, 122, 73)',
      borderColor: 'rgb(30, 174, 104)',
      backgroundColor: 'rgb(168, 240, 205)',
    },
  },
  pending: {
    colorScheme: {
      color: 'rgb(0, 73, 122)',
      borderColor: 'rgb(31, 165, 255, 0.8)',
      backgroundColor: 'rgb(71, 182, 255, 0.3)',
    },
  },
  in_transit: {
    colorScheme: {
      color: 'rgb(0, 0, 0)',
      borderColor: 'rgb(173, 173, 173)',
      backgroundColor: 'rgb(206, 206, 206, 0.4)',
    },
  },
  failed: {
    colorScheme: {
      color: 'rgb(144, 46, 39)',
      borderColor: 'rgb(172, 55, 47)',
      backgroundColor: 'rgb(233, 179, 175)',
    },
  },
  canceled: {
    colorScheme: {
      color: 'rgb(144, 46, 39)',
      borderColor: 'rgb(172, 55, 47)',
      backgroundColor: 'rgb(233, 179, 175)',
    },
  },
};
