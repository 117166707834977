import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as leagueActions from '../../../reducers/leaguesReducer';
import TabbedNavigation from '../../../shared-components/TabbedNavigation';
import PreviousBookerUserSearch from '../../users/components/PreviousBookerUserSearch';
import NewBookerUserCreation from '../../users/components/NewBookerUserCreation';
import CreateLeagueTeam from '../components/CreateLeagueTeam';
import { inviteTeamToLeague } from '../../../services/leaguesServices';

const InviteTeamsToLeague = () => {
  const user = useSelector((state) => state.reservation.userDetailsForReservation);
  const newUser = useSelector((state) => state.leagues.league.invitedUser);
  const league = useSelector((state) => state.leagues.league);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('INVITE_USER');

  const tabs = [
    {
      id: 0,
      name: 'Existing Customer',
      body: (
        <PreviousBookerUserSearch
          updateUserDetails={(updatedUserDetails) => dispatch(
            leagueActions.updateLeagueInviteUserDetails(updatedUserDetails),
          )}
          user={user}
        />
      ),
    },
    {
      id: 1,
      name: 'New Customer',
      onClick: () => dispatch(leagueActions.resetLeagueInviteUserDetails()),
      body: (
        <NewBookerUserCreation
          updateUserDetails={
            (updatedUserDetails) => dispatch(
              leagueActions.updateLeagueInviteUserDetails(updatedUserDetails),
            )
            }
          user={newUser}
          league
        />
      ),
    },
  ];

  return (
    <>
      <Button color="primary" variant="contained" onClick={() => setOpen(!open)}>
        Invite Teams
      </Button>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        classes={{ paper: { flexGrow: 1 } }}
      >
        <DialogContent>
          <div className="reservation-creation-user-selecton" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

            <ConditionallyVisible condition={selectedTab === 'CREATE_TEAM'}>
              <CreateLeagueTeam
                onSubmit={async (form) => {
                  const res = await inviteTeamToLeague(
                    league.id,
                    form,
                  );

                  if (res.error) throw new Error(res.error);

                  dispatch(leagueActions.updateLeagueTeams([...league.teams, res.data]));
                  setOpen(false);
                }}
              />
            </ConditionallyVisible>
            <ConditionallyVisible condition={selectedTab === 'INVITE_USER'}>
              <TabbedNavigation tabsToNavigate={tabs} />
            </ConditionallyVisible>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(leagueActions.resetLeagueInviteUserDetails());
              setOpen(false);
            }}
            color="secondary"
            variant="outlined"
          >
            Close
          </Button>
          <ConditionallyVisible condition={selectedTab === 'INVITE_USER'}>
            <Button
              onClick={() => {
                dispatch(leagueActions.inviteUserToLeague());
                setOpen(false);
              }}
              color="primary"
              variant="contained"
            >
              Invite User
            </Button>
          </ConditionallyVisible>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteTeamsToLeague;
