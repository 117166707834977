/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Button, Divider, Typography } from '@material-ui/core';

export const TaxExemptUsers = ({ companyInfo, updateCompany }) => {
  const dispatch = useDispatch();
  const [taxExemptUsers, setTaxExemptUsers] = useState(null);

  useEffect(() => {
    setTaxExemptUsers(companyInfo.taxExemptUsers);
  }, [companyInfo]);

  return (
    <>
      <Typography style={{ textDecoration: 'underline' }}>
        Tax Exempt Users
      </Typography>
      <PBInput
        id="taxExempt"
        type="toggle"
        label="Tax Exempt Users"
        value={taxExemptUsers}
        onChange={() => setTaxExemptUsers((prev) => !prev)}
      />
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={async () => {
          dispatch(
            updateCompany(
              companyInfo.id,
              { taxExemptUsers },
            ),
          );
        }}
      >
        Save
      </Button>

      <Divider />
    </>
  );
};
