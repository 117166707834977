import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import * as addonsActions from '../../../../reducers/addonsReducer';
import AddAmenityDialog from './AddAmenityDialog';
import AddAddonDialog from './AddAddonDialog';
import AddForcibleBookingDialog from './AddForcibleBookingDialog';
import AddForcibleBookingOffsetDialog from './AddForcibleBookingOffsetDialog';
import { updateFacilityAmenityService } from '../../../../services/facilitiesServices';
import { useToast } from '../../../../hooks';

const AmenityAddonConfiguration = ({
  facilities,
  managerAccess,
  requestFacilityAmenitiesRemoval,
  requestFacilityAddonsRemoval,
}) => {
  const toast = useToast();
  const handleDelete = (forcibleBooking) => {
    console.log(forcibleBooking, 'forcibleBooking');
    requestFacilityAmenitiesRemoval({
      amenityId: forcibleBooking.amenity.id,
      coreFacilityId: forcibleBooking.coreFacilityId,
    });
  };

  const handleDeleteAmenity = (amenity, facilityId) => {
    console.log(amenity, 'amenity');
    requestFacilityAmenitiesRemoval({
      amenityId: amenity.id,
      coreFacilityId: facilityId,
    });
  };

  const handleAddonDelete = (addon, selectedFacilityId) => {
    const facilityAmenities = {
      addonId: addon.id,
      facilityId: selectedFacilityId,
    };
    requestFacilityAddonsRemoval(facilityAmenities);
  };

  const handleDeleteOffsets = async (companyId, forcibleBooking) => {
    const { error } = await updateFacilityAmenityService(
      companyId,
      forcibleBooking.amenity.id,
      forcibleBooking.coreFacilityId,
      { offsets: null },
    );

    if (error) {
      return toast.trigger({
        type: 'error',
        message: 'Failed to delete offsets. Please try again.',
      });
    }

    return toast.trigger({
      type: 'success',
      message: 'Offsets successfully deleted!',
    });
  };

  const generateOffsetText = (offset) => {
    let offsetText = 'This booking will be created ';
    offsetText += offset.startOffset < 0 ? `${offset.startOffset * -1} minutes before ` : `${offset.startOffset} minutes after `;
    offsetText += offset.startAnchor === 'START' ? 'the start of the booking ' : 'the end of the booking ';
    if (offset.startAnchor === 'START' && offset.endAnchor === 'END') {
      offsetText += `and run until the end of the booking${offset.endOffset > 0 ? ` plus ${offset.endOffset} minutes` : ''}`;
    } else {
      offsetText += `for ${offset.endOffset} minutes`;
    }
    return offsetText;
  };

  const { selectedFacility, companyAmenities } = facilities;
  const { amenities, addons } = selectedFacility;

  return (
    <>
      <div style={{ display: 'flex', 'flex-direction': 'column', gap: '1rem' }}>
        <div>
          <Typography variant="h6">Amenities</Typography>
          {amenities ? (
            <>
              {amenities.length > 0 ? (
                <div style={{ display: 'flex', maxWidth: '400px', flexWrap: 'wrap' }}>
                  {amenities.map((element) => (
                    <div style={{ display: 'flex', width: '400px', margin: '10px 0px' }}>
                      <div style={{
                        width: '300px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                      }}
                      >
                        {element.name}
                      </div>
                      <Button
                        variant="contained"
                        onClick={() => handleDeleteAmenity(element, selectedFacility.id)}
                        style={{ color: '#ffffff', backgroundColor: '#B00020', marginLeft: '10px' }}
                      >
                        DELETE
                      </Button>
                    </div>
                  ))}
                </div>

              ) : <>No amenities found</>}
            </>
          ) : <>Loading...</>}
        </div>
        <AddAmenityDialog
          amenities={amenities}
          companyAmenities={companyAmenities}
          selectedFacilityId={selectedFacility.id}
        />
        <div>
          <Divider />
        </div>
        <div>
          <Typography variant="h6">Addons</Typography>
          {addons ? (
            <>
              {addons.length > 0 ? (
                <div style={{ display: 'flex', maxWidth: '400px', flexWrap: 'wrap' }}>
                  {addons.map((element) => (
                    <div style={{ display: 'flex', width: '400px', margin: '10px 0px' }}>
                      <div style={{
                        width: '300px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                      }}
                      >
                        {element.name}
                      </div>
                      <Button
                        variant="contained"
                        onClick={() => handleAddonDelete(element, selectedFacility.id)}
                        style={{ color: '#ffffff', backgroundColor: '#B00020', marginLeft: '10px' }}
                      >
                        DELETE
                      </Button>
                    </div>
                  ))}
                </div>

              ) : <>No Add Ons Found</>}
            </>
          ) : <>Loading...</>}
        </div>
        <AddAddonDialog
          addons={addons}
          selectedFacilityId={selectedFacility.id}
        />

        <div>
          <div style={{ marginBottom: '0.4rem' }}>
            <Typography variant="h6">Forcible Bookings</Typography>
            <Typography variant="subtitle2">These are used to force a booking on other facilities i.e. booking a kids party will force a booking for both a pitch and a function room.</Typography>
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <AlternativelyVisible condition={selectedFacility.forcibleBookings.length > 0}>
              <>
                {
                  selectedFacility.forcibleBookings.map((forcibleBooking) => (
                    <React.Fragment key={forcibleBooking.id}>
                      <div style={{ display: 'flex', width: '800px', margin: '10px 0px' }}>
                        <div style={{
                          width: '600px', padding: '10px', borderRadius: '4px', border: '1px solid #4581E2',
                        }}
                        >
                          {forcibleBooking.amenity.name}
                          {forcibleBooking.offsets?.length > 0 && (
                            <Typography variant="subtitle2">
                              {forcibleBooking.offsets.map((offset) => (
                                <div>
                                  {generateOffsetText(offset)}
                                </div>
                              ))}
                            </Typography>
                          )}
                        </div>
                        <div style={{
                          display: 'flex', gap: '0.5rem', marginLeft: '1rem',
                        }}
                        >
                          {!forcibleBooking.offsets || forcibleBooking.offsets?.length <= 0 ? (
                            <AddForcibleBookingOffsetDialog
                              forcibleBooking={forcibleBooking}
                              companyId={selectedFacility.companyId}
                            />
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => handleDeleteOffsets(
                                selectedFacility.companyId, forcibleBooking,
                              )}
                              style={{ color: '#B00020', borderColor: '#B00020' }}
                            >
                              Delete Offsets
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            onClick={() => handleDelete(forcibleBooking)}
                            style={{ color: '#ffffff', backgroundColor: '#B00020' }}
                          >
                            Delete Booking
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                }
              </>

              <>
                No Forcible Bookings Found
              </>
            </AlternativelyVisible>
          </div>
          <AddForcibleBookingDialog />
        </div>

      </div>
    </>
  );
};

AmenityAddonConfiguration.propTypes = {
  facilities: PropTypes.shape().isRequired,
  managerAccess: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFacility: PropTypes.shape().isRequired,
  requestFacilityAddonsRemoval: PropTypes.func.isRequired,
  requestFacilityAmenitiesRemoval: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    facilities, companies, accessRestrictions,
  } = state;
  const { selectedFacilityId } = facilities;
  const { currentUser } = state.currentUser;
  const { managerAccess } = currentUser;
  return {
    facilities,
    companies,
    accessRestrictions,
    managerAccess,
    selectedFacilityId,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedFacilityStore: (id) => dispatch(facilitiesActions.updateSelectedFacilityStore(id)),
  requestSelectedFacilityUpdate: (facilityId, facilityData) => dispatch(
    facilitiesActions.requestSelectedFacilityUpdate(facilityId, facilityData),
  ),
  requestFacilityAmenitiesRemoval: (
    facilityAmenities,
  ) => dispatch(facilitiesActions.requestFacilityAmenitiesRemoval(facilityAmenities)),
  requestFacilityAddonsRemoval: (
    facilityAmenities,
  ) => dispatch(addonsActions.requestFacilityAddonsRemoval(facilityAmenities)),
  backToFacilities: () => dispatch(push('/facilities')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmenityAddonConfiguration);
